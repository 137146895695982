<template>
  <div>
    <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      :title="Title"
    >
      <base-form
        :componentList="updateFrom"
        :showBtns="false"
        :formAttrs="{
          model: updateData,
          labelWidth: '120px',
        }"
        class="formStyle"
        ref="changeFromData"
      ></base-form>
      <template slot="footer">
        <base-button label="提交" @click="submit" />
        <base-button
          label="关闭"
          type="default"
          @click="visibleState = false"
        />
      </template>

    </base-dialog>
      <!-- 文件预览 -->
    <pre-view
      :fileId="previewData.fileId"
      :isOpen="true"
      :fileType="previewData.fileType"
      :count="count"
    />
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import { updateFrom } from '../utils/enterprise-information-config'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { supplierChange, supplierExecutor, supplierLimitConsumption } from '@/api/companyApi'
export default {
  components: { BaseDialog, BaseForm, BaseButton, PreView },
  props: {
    dialogVisible: Boolean,
    Title: String,
    formData: Object,
    type: String,
    dataType: String
  },
  data () {
    return {
      previewData: {},
      count: 0,
      updateData: {}
    }
  },
  // 计算属性 类似于data概念
  computed: {
    updateFrom () {
      return updateFrom(this)
    },
    api () {
      let api
      if (this.type === 'enterprise-information-change') {
        // 企业变更记录
        api = supplierChange
      } else if (this.type === 'consumption-restriction-order') {
        // 限制消费令记录
        api = supplierLimitConsumption
      } else if (this.type === 'executor-record') {
        // 执行人记录
        api = supplierExecutor
      }
      return api
    },
    visibleState: {
      get () {
        return this.dialogVisible
      },
      set (val) {
        this.$emit('update:dialogVisible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visibleState (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.changeFromData.$forceUpdate()
          this.$refs.changeFromData.clearValidate()
        })
        this.updateData = {
          ...this.formData,
          fileName: this.formData.finAttachmentList?.[0]?.fileName
        }
      }
    }
  },
  // 方法集合
  methods: {
    // 刷新组件
    updateForm () {
      this.$nextTick(() => {
        this.$refs.changeFromData.$forceUpdate()
      })
    },
    previewShow () {
      this.previewData = {
        fileId: this.updateData.finAttachmentList[0].fileId,
        fileType: this.updateData.finAttachmentList[0].fileName.replace(/.+\./, '')
      }
      this.count++
    },
    // 数据提交
    submit () {
      let status = true
      this.$refs.changeFromData.validate(valid => {
        if (!valid) {
          status = false
        }
      })
      if (status) {
        this.api.saveOrUpdate(this.updateData).then(res => {
          if (res.data) {
            this.refreshList(this.dataType === 'add' ? '新增成功' : '修改成功')
          }
        })
      }
    },
    // 关闭弹窗 重载列表
    refreshList (text) {
      this.success(text)
      this.visibleState = false
      this.$emit('refreshList', true)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped></style>
