import {
  getDictLists
} from '@/filters/fromDict'
// import supplierSelect from '@/components/packages/supplier-select/supplier-select.vue'
import BaseSelect from '@/components/common/base-select/base-select'
// import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
import fileUpdate from '../components/file-update.vue'
import BaseButton from '@/components/common/button/base-button/base-button'
import Amountinput from '@/components/input/amount-input/amount-input'
import {
  formatDate
} from '@/utils/auth/common'

// 修改数据弹窗
const startDatePicker = {
  disabledDate (time) {
    return time.getTime() < new Date('1990-01-01') || time.getTime() > new Date('2100-01-01')
  }
}
export const addFrom = (content) => {
  return [{
    label: '变更事项',
    prop: 'changeItem',
    span: 8,
    attrs: {
      placeholder: '请输入'
    },
    isHidden: content.type !== 'enterprise-information-change'

  }, {
    label: '变更时间',
    prop: 'changeTime',
    tag: 'el-date-picker',
    span: 8,
    attrs: {
      startPlaceholder: '请选择开始时间',
      endPlaceholder: '请选择结束时间',
      type: 'daterange',
      'value-format': 'yyyy-MM-dd',
      pickerOptions: startDatePicker
    },
    isHidden: content.type !== 'enterprise-information-change'
  }, {
    label: '企业信息',
    prop: 'companyInfo',
    span: 8,
    attrs: {
      placeholder: '请输入'
    },
    isHidden: content.type !== 'consumption-restriction-order'

  }, {
    label: '限制消费者名称',
    prop: 'restrictConsumerName',
    span: 8,
    attrs: {
      placeholder: '请输入'
    },
    isHidden: content.type !== 'consumption-restriction-order'

  }, {
    label: '被执行人名称',
    prop: 'beExecutorName',
    span: 8,
    attrs: {
      placeholder: '请输入'
    },
    isHidden: content.type !== 'executor-record'

  }, {
    label: '身份证号码/组织机构号码',
    prop: 'partyCardNum',
    span: 8,
    attrs: {
      placeholder: '请输入'
    },
    isHidden: content.type !== 'executor-record'

  }]
}
export const addTable = (content) => {
  return [{
    type: 'selection',
    width: '55px'
  },
  {
    label: '变更事项',
    prop: 'changeItem',
    'show-overflow-tooltip': true,
    align: 'left',
    isHidden: content.type !== 'enterprise-information-change'
  },
  {
    label: '变更前',
    prop: 'contentBefore',
    'show-overflow-tooltip': true,
    minWidth: '120px',
    align: 'left',
    isHidden: content.type !== 'enterprise-information-change'
  },
  {
    label: '变更后',
    prop: 'contentAfter',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    isHidden: content.type !== 'enterprise-information-change'
  },
  {
    label: '变更时间',
    prop: 'changeTime',
    width: 90,
    'show-overflow-tooltip': true,
    align: 'left',
    formatter: row => {
      return formatDate(row.changeTime, 'YY-MM-DD')
    },
    isHidden: content.type !== 'enterprise-information-change'
  },
  {
    label: '企业信息', // 限制消费令记录
    prop: 'companyInfo',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left',

    isHidden: content.type !== 'consumption-restriction-order'
  },
  {
    label: '限制消费者名称', // 限制消费令记录
    prop: 'restrictConsumerName',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left',

    isHidden: content.type !== 'consumption-restriction-order'
  },
  {
    label: '立案时间', // 限制消费令记录
    prop: 'caseCreateTime',
    width: 90,
    'show-overflow-tooltip': true,
    align: 'left',
    formatter: row => {
      return formatDate(row.caseCreateTime, 'YY-MM-DD')
    },
    isHidden: content.type !== 'consumption-restriction-order'
  },
  {
    label: '申请人信息', // 限制消费令记录
    prop: 'applicantInfo',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left',

    isHidden: content.type !== 'consumption-restriction-order'
  },
  {
    label: '发布日期', // 限制消费令记录
    prop: 'publishDate',
    width: 90,
    'show-overflow-tooltip': true,
    align: 'left',
    formatter: row => {
      return formatDate(row.publishDate, 'YY-MM-DD')
    },
    isHidden: content.type !== 'consumption-restriction-order'
  },
  {
    label: '被执行人名称', // 被执行人记录
    prop: 'beExecutorName',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left',

    isHidden: content.type !== 'executor-record'
  },
  {
    label: '身份证号/组织机构代码', // 被执行人记录
    prop: 'partyCardNum',
    minWidth: '170px',
    'show-overflow-tooltip': true,
    align: 'left',

    isHidden: content.type !== 'executor-record'
  },
  {
    label: '执行标的（元）', // 被执行人记录
    prop: 'execMoney',
    minWidth: '135px',
    'show-overflow-tooltip': true,
    align: 'right',
    formatter: row => {
      return (row.execMoney ? parseFloat(row.execMoney).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
    },
    isHidden: content.type !== 'executor-record'
  },
  {
    label: '执行法院', // 被执行人记录
    prop: 'execCourtName',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left',

    isHidden: content.type !== 'executor-record'
  },
  {
    label: '创建时间',
    prop: 'createTime',
    width: 90,
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.createTime, 'YY-MM-DD')
    },
    isHidden: content.type !== 'enterprise-information-change' && content.type !== 'executor-record'
  },
  {
    label: '数据来源',
    prop: 'dataSources',
    minWidth: '100px',
    formatter: row => {
      return row.dataSources === 'TIANYANCHA' ? '天眼查同步' : '用户添加'
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '120px'
    // isHidden: content.type !== 'enterprise-information-change'
  },
  {
    label: '附件',
    prop: 'finAttachmentList',
    minWidth: '320px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  },
  {
    label: '操作',
    prop: 'action',
    width: '100px'
  }

  ]
}
//  新增信息表单
export const updateFrom = (content) => {
  console.log(content.formData, 'content.formData')
  return [

    {
      label: '变更事项',
      prop: 'changeItem',
      span: 12,
      rules: [{ required: true, message: '请输入变更事项', trigger: 'blur' }],
      isHidden: content.type !== 'enterprise-information-change',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '变更时间',
      prop: 'changeTime',
      span: 12,
      tag: 'el-date-picker',
      rules: [{ required: true, message: '请选择变更时间', trigger: 'blur' }],
      isHidden: content.type !== 'enterprise-information-change',
      attrs: {
        placeholder: '请选择',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        'value-format': 'timestamp',
        pickerOptions: startDatePicker
      }
    },
    {
      label: '变更前内容',
      prop: 'contentBefore',
      span: 24,
      rules: [{ required: true, message: '请输入变更前内容', trigger: 'blur' }],
      isHidden: content.type !== 'enterprise-information-change',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        type: 'textarea'
      }
    },
    {
      label: '变更后内容',
      prop: 'contentAfter',
      span: 24,
      rules: [{ required: true, message: '请输入变更后内容', trigger: 'blur' }],
      isHidden: content.type !== 'enterprise-information-change',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        type: 'textarea'
      }
    },
    {
      label: '企业信息',
      prop: 'companyInfo',
      span: 12,
      rules: [{ required: true, message: '请输入企业信息', trigger: 'blur' }],
      isHidden: content.type !== 'consumption-restriction-order',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '限制消费者名称',
      prop: 'restrictConsumerName',
      span: 12,
      rules: [{ required: true, message: '请输入限制消费者名称', trigger: 'blur' }],
      isHidden: content.type !== 'consumption-restriction-order',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '立案时间',
      prop: 'caseCreateTime',
      span: 12,
      rules: [{ required: true, message: '请选择立案时间', trigger: 'blur' }],
      isHidden: content.type !== 'consumption-restriction-order',
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请选择',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        'value-format': 'timestamp',
        pickerOptions: startDatePicker
      }
    },
    {
      label: '申请人信息',
      prop: 'applicantInfo',
      span: 24,
      rules: [{ required: true, message: '请输入申请人信息', trigger: 'blur' }],
      isHidden: content.type !== 'consumption-restriction-order',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        type: 'textarea'
      }
    },
    {
      label: '发布日期',
      prop: 'publishDate',
      span: 12,
      rules: [{ required: true, message: '请选择发布日期', trigger: 'blur' }],
      isHidden: content.type !== 'consumption-restriction-order',
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请选择',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        'value-format': 'timestamp',
        pickerOptions: startDatePicker
      }
    },
    {
      label: '被执行人名称',
      prop: 'beExecutorName',
      span: 12,
      rules: [{ required: true, message: '请输入被执行人名称', trigger: 'blur' }],
      isHidden: content.type !== 'executor-record',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '身份证号/组织机构代码',
      prop: 'partyCardNum',
      span: 12,
      rules: [{ required: true, message: '请输入身份证号/组织机构代码', trigger: 'blur' }],
      isHidden: content.type !== 'executor-record',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '执行标的(元)',
      prop: 'execMoney',
      span: 12,
      rules: [{ required: true, message: '请输入执行标的', trigger: 'blur' }],
      isHidden: content.type !== 'executor-record',
      tag: Amountinput,
      attrs: {
        placeholder: '请输入',
        type: 'Y',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '执行法院',
      prop: 'execCourtName',
      span: 12,
      rules: [{ required: true, message: '请输入执行法院', trigger: 'blur' }],
      isHidden: content.type !== 'executor-record',
      attrs: {
        placeholder: '请输入',
        disabled: content.updateData.dataSources === 'TIANYANCHA'
      }
    },
    {
      label: '创建时间',
      prop: 'createTime',
      span: 12,
      rules: [{ required: true, message: '请选择创建时间', trigger: 'blur' }],
      isHidden: content.type !== 'executor-record',
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请选择',
        disabled: content.updateData.dataSources === 'TIANYANCHA',
        'value-format': 'timestamp',
        pickerOptions: startDatePicker
      }
    },
    {
      label: '备注',
      prop: 'remark',
      span: 24,
      rules: [{ required: false, message: '请输入变更事项', trigger: 'blur' }],
      // isHidden: content.type !== 'enterprise-information-change',
      attrs: {
        placeholder: '请输入',
        type: 'textarea'
      }
    },
    {
      label: '附件',
      prop: 'fileName',
      attrs: {
        placeholder: '请上传文件',
        generateFileId: content.updateData.finAttachmentList?.[0]?.fileId
      },
      span: 13,
      tag: fileUpdate,
      rules: [{
        required: false,
        message: '请上传文件',
        trigger: 'change'
      }],
      on: {
        generate: (data) => {
          content.send('generate')
        },
        upload: (data) => {
          content.$set(content.updateData, 'finAttachmentList', [data])
          content.updateForm()
        },
        preview: (data) => {
          content.previewShow()
        },
        delFile: () => {
          content.$set(content.updateData, 'finAttachmentList', [])
          content.$set(content.updateData, 'fileName', '')
          content.updateForm()
        }
      }
    }

  ]
}
//  企业信息
export const enterpriseConfig = (content) => {
  return [

    {
      label: '供应商名称',
      prop: 'companyName',
      cols: {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 6,
        xl: 6
      },

      attrs: {

        placeholder: '请输入',
        disabled: true
      }

    },

    {
      label: '检查期数',
      prop: 'periodNo',
      cols: {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 6,
        xl: 6
      },

      attrs: {
        placeholder: '请输入',
        disabled: true
      }

    },
    {
      label: '',
      prop: 'button',
      attrs: {
        label: '加载上期数据',
        icon: 'iconfont iconfont iconicrefresh'
      },
      isHidden: content.contractInfo.MakeType === 'showInfo',
      tag: BaseButton,
      on: {
        click (data) {
          content.getPreviousData()
        }
      }
    }

  ]
}
// 客户评价情况
export const ddProjectCommentVOsConfig = (content, index) => {
  const cols = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 5,
    xl: 6
  }
  return [{
    label: '人员类型',
    prop: 'assessType',
    attrs: {
      placeholder: '请选择',
      options: getDictLists('EVALUATOR_TYPE'),
      selectedField: ['dictId', 'dictName'],
      disabled: content.MakeType === 'showInfo'
    },
    tag: BaseSelect,
    cols
  },
  {
    label: '评价人姓名',
    prop: 'assessName',
    attrs: {
      placeholder: '请输入',
      maxlength: '100',
      disabled: content.MakeType === 'showInfo'
    },
    cols
  }

  ]
}
// 访谈总结
export const summaryConfig = (content, index) => {
  return [{
    label: '访谈情况总结',
    prop: 'interviewSummary',
    attrs: {
      placeholder: '请输入供应商实控人、财务、同行评价、其他地产评价',
      type: 'textarea',
      maxlength: '500',
      rows: '5',
      'show-word-limit': true,
      disabled: content.MakeType === 'showInfo'
    },

    span: 24
  }

  ]
}
// 企业信息变更记录
export const changeRecordColumn = (content) => {
  return [{
    label: '变更事项',
    prop: 'changeItem',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '变更前',
    prop: 'contentBefore',
    'show-overflow-tooltip': true,
    minWidth: '120px',
    align: 'left'
  },
  {
    label: '变更后',
    prop: 'contentAfter',
    minWidth: '120px',
    'show-overflow-tooltip': true
  },
  {
    label: '变更时间',
    prop: 'changeTime',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left',
    formatter: row => {
      return formatDate(row.changeTime, 'YY-MM-DD')
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.createTime, 'YY-MM-DD')
    }

  },
  {
    label: '附件',
    prop: 'finAttachmentList',
    minWidth: '300px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  },
  {
    label: '操作',
    prop: 'action',
    minWidth: '135px'
  }

  ]
}
// 限制消费变更记录
export const consumptionRecordColumn = (content) => {
  return [{
    label: '企业信息',
    prop: 'companyInfo',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '限制消费者名称',
    prop: 'restrictConsumerName',
    'show-overflow-tooltip': true,
    minWidth: '120px',
    align: 'left'
  },
  {
    label: '立案时间',
    prop: 'caseCreateTime',
    minWidth: '120px',
    formatter: row => {
      return formatDate(row.caseCreateTime, 'YY-MM-DD')
    },
    'show-overflow-tooltip': true
  },
  {
    label: '申请人信息',
    prop: 'applicantInfo',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'right'

  },
  {
    label: '发布日期',
    prop: 'publishDate',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.publishDate, 'YY-MM-DD')
    }

  },
  {
    label: '附件',
    prop: 'fileId',
    minWidth: '300px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  },
  {
    label: '操作',
    prop: 'action',
    minWidth: '135px'
  }

  ]
}
// 被执行人
export const executorRecordColumn = (content) => {
  return [{
    label: '被执行人名称',
    prop: 'beExecutorName',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '身份证号/组织机构代码',
    prop: 'partyCardNum',
    'show-overflow-tooltip': true,
    minWidth: '120px'

  },
  {
    label: '执行标的（元）',
    prop: 'execMoney',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    formatter: row => {
      return (row.execMoney ? parseFloat(row.execMoney).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
    }
  },
  {
    label: '执行法院',
    prop: 'execCourtName',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    align: 'left'

  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '120px',
    'show-overflow-tooltip': true,
    // align: 'left',
    formatter: row => {
      return formatDate(row.createTime, 'YY-MM-DD')
    }

  },
  {
    label: '附件',
    prop: 'fileId',
    minWidth: '300px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  },
  {
    label: '操作',
    prop: 'action',
    minWidth: '135px'
  }

  ]
}
// 融资担保情况变动及重大逾期情况
export const overdueSituationColumn = (content) => {
  return [{
    label: '序号',
    prop: 'code'

  },
  {
    label: '时间节点',
    prop: 'timeNode',

    align: 'left',
    formatter: row => {
      return formatDate(row.timeNode, 'YY-MM-DD')
    }

  },
  {
    label: '贷款余额(万元)',
    prop: 'loanBalance',
    HiddenOverflow: true,
    minWidth: '210px',
    align: 'left',
    formatter: row => {
      return row.loanBalance ? row.loanBalance / 10000 : '0'
    }
  },
  {
    label: '担保余额(万元)',
    prop: 'guaranteeBalance',
    minWidth: '210px',
    HiddenOverflow: true,
    formatter: row => {
      return row.guaranteeBalance ? row.guaranteeBalance / 10000 : '0'
    }
  },
  {
    label: '下月到期贷款(万元)',
    prop: 'expiresNextMonthAmount',
    minWidth: '210px',
    HiddenOverflow: true,
    align: 'right',
    formatter: row => {
      return row.expiresNextMonthAmount ? row.expiresNextMonthAmount / 10000 : '0'
    }

  },
  {
    label: '下月预计新增贷款(万元)',
    prop: 'addNextMonthAmount',
    HiddenOverflow: true,
    minWidth: '210px',
    formatter: row => {
      return row.addNextMonthAmount ? row.addNextMonthAmount / 10000 : '0'
    }

  },

  {
    label: '附件',
    prop: 'fileId',
    minWidth: '300px',
    HiddenOverflow: true,
    showOverflowTooltip: false
  }

  ]
}
// 文件生成与下载
export const fileConfig = (content) => {
  const span = 12
  return [{
    label: '文件名称',
    prop: 'fileName',
    attrs: {
      placeholder: '请生成或上传文件',
      generateFileId: '22222222222'
    },
    span,
    tag: fileUpdate,
    rules: [{
      required: true,
      message: '请生成或上传文件',
      trigger: 'change'
    }],
    on: {
      generate: (data) => {
        content.send('generate')
      },
      upload: (data) => {
        content.formData.fileListInfo.fileId = data.fileId
      },
      preview: (data) => {
        content.previewShow()
      },
      delFile: () => {
        content.delFile()
      }
    }
  }]
}
